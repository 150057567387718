<template>
  <div :id="`${tableName}-${id}-data-table-actions`">
    <div class="text-nowrap text-center">
      <b-dropdown v-if="displayMode === 'dropdown' && showDropdown" :id="`${tableName}-${id}-data-table-dropdown`" no-caret variant="link">
        <template #button-content>
          <feather-icon class="align-middle text-body" icon="MoreVerticalIcon" size="16" />
        </template>
        <!--show-->
        <b-dropdown-item v-if="showShow" @click.prevent="$emit('show')">
          <feather-icon :id="`${tableName}-${id}-data-table-dropdown-action-show`" icon="EyeIcon" size="16" />
          <span class="align-middle ml-50">{{ $t('action.show') }}</span>
        </b-dropdown-item>
        <!--duplicate-->
        <b-dropdown-item v-if="showDuplicate" @click.prevent="$emit('duplicate')">
          <feather-icon :id="`${tableName}-${id}-data-table-dropdown-action-duplicate`" icon="CopyIcon" size="16" />
          <span class="align-middle ml-50">{{ $t('action.duplicate') }}</span>
        </b-dropdown-item>
        <!--edit-->
        <b-dropdown-item v-if="showEdit" @click.prevent="$emit('edit')">
          <feather-icon :id="`${tableName}-${id}-data-table-dropdown-action-edit`" color="#e1ca93" icon="Edit2Icon" size="16" />
          <span class="align-middle text-primary ml-50">{{ $t('action.edit') }}</span>
        </b-dropdown-item>
        <!--delete-->
        <b-dropdown-item v-if="showDelete" @click.prevent="$emit('delete')">
          <feather-icon :id="`${tableName}-${id}-data-table-dropdown-action-delete`" color="#ea5455" icon="TrashIcon" size="16" />
          <span class="align-middle text-danger ml-50">{{ $t('action.delete') }}</span>
        </b-dropdown-item>
      </b-dropdown>
      <!--cancel-->
      <b-button
        v-if="showCancel"
        :id="`${tableName}-${id}-data-table-action-cancel`"
        :class="['btn-icon', { 'mr-1': showSave, 'isolated-btn ': !showDropdown }]"
        size="sm"
        variant="outline-secondary"
        @click.prevent="$emit('cancel')"
      >
        <feather-icon icon="SlashIcon" size="13" />
      </b-button>
      <!--save-->
      <b-button
        v-if="showSave"
        :id="`${tableName}-${id}-data-table-action-save`"
        :class="['btn-icon save-btn', { 'mr-1': showAdd, 'isolated-btn ': !showDropdown }]"
        size="sm"
        variant="outline-primary"
        @click.prevent="$emit('save')"
      >
        <feather-icon icon="SaveIcon" size="14" />
      </b-button>
      <!--add-->
      <b-button
        v-if="showAdd"
        :id="`${tableName}-${id}-data-table-action-add`"
        class="btn-icon"
        size="sm"
        variant="outline-primary"
        @click.prevent="$emit('add')"
      >
        <feather-icon icon="PlusIcon" size="13" />
      </b-button>
    </div>

    <div v-if="displayMode === 'inline-icon'" class="text-nowrap text-center">
      <!--show-->
      <feather-icon
        v-if="showShow"
        :id="`${tableName}-${id}-data-table-action-show`"
        icon="EyeIcon"
        size="16"
        style="cursor: pointer; width: 40px"
        @click.prevent="$emit('show')"
      />
      <!-- <b-tooltip
        v-if="showShow"
        :title="$t('action.show')"
        class="cursor-pointer"
        :target="`${tableName}-${id}-data-table-action-show`"
      /> -->
      <!--duplicate-->
      <feather-icon
        v-if="showDuplicate"
        :id="`${tableName}-${id}-data-table-action-duplicate`"
        icon="CopyIcon"
        size="16"
        style="cursor: pointer; width: 40px"
        @click.prevent="$emit('duplicate')"
      />
      <!-- <b-tooltip
        v-if="showDuplicate"
        :title="$t('action.duplicate')"
        class="cursor-pointer"
        :target="`${tableName}-${id}-data-table-action-duplicate`"
      /> -->
      <!--edit-->
      <feather-icon
        v-if="showEdit"
        :id="`${tableName}-${id}-data-table-action-edit`"
        color="#e1ca93"
        icon="Edit2Icon"
        size="16"
        style="cursor: pointer; width: 40px"
        @click.prevent="$emit('edit')"
      />
      <!-- <b-tooltip
        v-if="showEdit"
        :title="$t('action.edit')"
        class="cursor-pointer"
        :target="`${tableName}-${id}-data-table-action-edit`"
      /> -->
      <!--delete-->
      <feather-icon
        v-if="showDelete"
        :id="`${tableName}-${id}-data-table-action-delete`"
        color="#ee7877"
        icon="TrashIcon"
        size="16"
        style="cursor: pointer; width: 40px"
        @click.prevent="$emit('delete')"
      />
      <!-- <b-tooltip
        v-if="showDelete"
        :title="$t('action.delete')"
        class="cursor-pointer"
        :target="`${tableName}-${id}-data-table-action-delete`"
      /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDataTableActions',

  props: {
    tableName: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
      // required: true,
    },
    showDropdown: {
      type: Boolean,
      default: true,
    },
    showShow: {
      type: Boolean,
      default: true,
    },
    showDuplicate: {
      type: Boolean,
      default: true,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    condensed: {
      type: String,
      default: 'md',
    },
    size: {
      type: String,
      default: 'sm',
    },
    displayMode: {
      type: String,
      default: 'dropdown',
    },
  },

  computed: {
    breakpoint() {
      return ['sm', 'xs', 'md', 'lg', 'xl'].indexOf(this.condensed) !== -1 ? this.condensed : 'md'
    },
  },
}
</script>

<style lang="scss" scoped>
.save-btn {
  box-shadow: 1px 1px 20px 1px $primary !important;
  &:hover {
    box-shadow: none !important;
  }
}
.isolated-btn {
  margin-top: 0.286rem !important;
}
// .dropdown-menu {
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   transform: translate3d(1426px, 356px, 0px);
//   display: block;
//   opacity: 1;
//   // outline: 0;
//   z-index: 1070 !important;
//   display: block;
//   // opacity: 0;
// }
</style>
